/** @jsxImportSource preact */
import { track } from '$/utils/integrations'
import { useEffect } from 'preact/hooks'

export default function EventTracking() {
  useEffect(() => {
    track('pageview')
  }, [])

  return null
}
